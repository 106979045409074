import React from "react"
import "./IntelBanner.less"
export default function ({
  showLogo = false,
  color = "#fff",
}: {
  showLogo?: boolean
  color?: string
}) {
  return (
    <div className="intelBanner" style={{ color }}>
      Powered by Intel® Xeon® processors
      {showLogo && (
        <img
          src="/intel-inside.png"
          style={{ width: "64px", marginLeft: "12px" }}
        />
      )}
    </div>
  )
}
