import React, { useEffect } from 'react'
import { Helmet as _Helmet } from 'react-helmet'
import { lang, loadScriptToHead } from '../utils'
const DOMAIN = process.env.GATSBY_DOMAIN

const Helmet: any = _Helmet
export function getCookie(name) {
  if (typeof window !== 'undefined') {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
  }
}

// const loadGTM = () => {
//   const gaId = hostnameToGtag[window.location.hostname] || "GTM-584G6JH"
//   ;(function (w, d, s, l, i) {
//     w[l] = w[l] || []
//     w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
//     var f = d.getElementsByTagName(s)[0],
//       j: any = d.createElement(s),
//       dl = l != "dataLayer" ? "&l=" + l : ""
//     j.async = true
//     j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
//     f.parentNode.insertBefore(j, f)
//   })(window, document, "script", "dataLayer", gaId)
// }

export const loadZoom = () => {
  // loadGTM()
  if (window.location.host === 'www.zenlayer.com') {
    var zi = document.createElement('script')
    zi.type = 'text/javascript'
    zi.async = true
    zi.src = 'https://ws.zoominfo.com/pixel/zhERMnd23D9lOQ8d7HVW'
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(zi, s)
  }
}
const loadScriptSrc = (src: string) => {
  var hm = document.createElement('script')

  hm.src = src

  var s = document.getElementsByTagName('script')[0]

  s.parentNode.insertBefore(hm, s)
}
const SEO = ({
  title,
  description,
  children,
  keywords = [],
  noIndex = false,
  featuredImage = '/featuredImage/index.jpg',
  viewport,
}: {
  title: string
  description: string
  keywords?: string[]
  featuredImage?: string
  children?: any
  noIndex?: boolean
  viewport?: string
}) => {
  const cookieEnabled =
    lang === 'en' &&
    getCookie('newConsent1') === 'true' &&
    process.env.NODE_ENV === 'production'
  // const cookieEnabled = true
  useEffect(() => {
    ;(window as any).dataLayer = (window as any).dataLayer || []
    if (lang === 'en') {
      loadScriptToHead(`
  window.intercomSettings = {
    app_id: "ruxumodu"
  };`)
      loadScriptToHead(`
      // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/ruxumodu'
;(function () {
  var w = window
  var ic = w.Intercom
  if (typeof ic === "function") {
    ic("reattach_activator")
    ic("update", w.intercomSettings)
  } else {
    var d = document
    var i = function () {
      i.c(arguments)
    }
    i.q = []
    i.c = function (args) {
      i.q.push(args)
    }
    w.Intercom = i
    var l = function () {
      var s = d.createElement("script")
      s.type = "text/javascript"
      s.async = true
      s.src = "https://widget.intercom.io/widget/ruxumodu"
      var x = d.getElementsByTagName("script")[0]
      x.parentNode.insertBefore(s, x)
    }
    console.log('manuallyload')
    l()
  }
})()
`)
    }
    //     const calendlyUrl =
    //       DOMAIN === 'in'
    //         ? '"https://calendly.com/zenlayer-india/15-min"'
    //         : '"https://calendly.com/zenlayer-website/15min"'
    //     if (lang === 'en') {
    //       loadScriptToHead(`
    //   window.onload = function() { Calendly.initBadgeWidget({ url: ${calendlyUrl}, text: 'Schedule time with me', color: '#1dbfff', textColor: '#ffffff', branding: true }); }
    // `)
    //     }
    loadZoom()
  }, [])
  return (
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Open+Sans:400italic,600italic,700italic,400,600,700"
        rel="stylesheet"
        type="text/css"
      />

      <script
        type="text/javascript"
        src="https://cdn.cookielaw.org/consent/04802fb9-9d79-42c1-ab3a-412332a76c39/OtAutoBlock.js"
      ></script>
      <script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-language="en"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script="04802fb9-9d79-42c1-ab3a-412332a76c39"
      ></script>
      {/* {DOMAIN === 'in' && (
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'Zenlayer',
            alternateName: 'Zenlayer',
            url: 'https://www.zenlayer.in/',
            logo: 'https://www.zenlayer.in/logo-blue.svg',
            contactPoint: {
              '@type': 'ContactPoint',
              telephone: '+91 77770 16848 / +91 99870 04266',
              contactType: 'technical support',
              contactOption: 'HearingImpairedSupported',
              areaServed: 'IN',
              availableLanguage: 'en',
            },
            sameAs: [
              'https://twitter.com/zenlayer',
              'https://www.linkedin.com/company/zenlayer',
              'https://www.facebook.com/zenlayer/',
            ],
          })}
        </script>
      )} */}
      {/* <script
        type="text/javascript"
        src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      ></script> */}

      {noIndex ? (
        <meta name="googlebot" content="noindex" />
      ) : (
        <meta name="robots" content="index, follow" />
      )}
      <script type="text/javascript">function OptanonWrapper() {}</script>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta http-equiv="cache-control" content="no-cache" />
      <meta charSet="utf-8" />
      <link rel="apple-touch-icon" href="/logo-white.svg"></link>
      <meta
        name="viewport"
        content={viewport || 'width = 640'}
        initial-scale="1"
      />
      <meta name="description" content={description} />
      <meta
        name="naver-site-verification"
        content="ceff858283bc18f2d2a439fd70370b388cea0661"
      />
      <meta
        name="google-site-verification"
        content="XeAbtwBd2pksHJ_GrqPVpk4fqEGnTqRoGkKoeAAyKl8"
      />
      <meta name="keywords" content={keywords.join(',')} />
      {featuredImage && <meta property="og:image" content={featuredImage} />}
      {featuredImage && <meta name="twitter:image" content={featuredImage} />}
      <title>{title}</title>
      {cookieEnabled && (
        <script type="text/javascript" src="/pardot-trace.js"></script>
      )}
      {lang !== 'zh' && (
        <script type="text/javascript" src="/leadfeeder.js"></script>
      )}
      {children}
      <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      {/* {lang === 'zh' && (
        <script type="text/javascript" src="/baidu-trace.js"></script>
      )} */}
      <html lang={DOMAIN === 'in' ? 'en-in' : 'en'} />
      <script
        src={
          DOMAIN === 'cn'
            ? 'https://www.recaptcha.net/recaptcha/api.js'
            : 'https://www.google.com/recaptcha/api.js'
        }
      ></script>
      {/* <link
        href="https://assets.calendly.com/assets/external/widget.css"
        rel="stylesheet"
      />
      <script
        src="https://assets.calendly.com/assets/external/widget.js"
        type="text/javascript"
        async
      ></script> */}
      {/* <script type="text/javascript">window.onload = function() { Calendly.initBadgeWidget({ url: 'https://calendly.com/zenlayer-website/15min', text: 'Schedule time with me', color: '#0069ff', textColor: '#ffffff', branding: true }); }</script> */}

      {/* {lang === "en" && (
        <script type="text/javascript" src="/cookie.js"></script>
      )}
      {lang === "en" && (
        <script
          type="text/javascript"
          src="//cdn.iubenda.com/cs/ccpa/stub.js"
        ></script>
      )}
      {lang === "en" && (
        <script
          type="text/javascript"
          src="//cdn.iubenda.com/cs/iubenda_cs.js"
          charSet="UTF-8"
          async
        ></script>
      )} */}
    </Helmet>
  )
}

export default SEO
